import React from "react"
import { Link } from "gatsby"

import "./css/index.scss"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"

const NotFoundPage = () => (
  <Layout>
    <SEO title="QueroPedir - 404" description="Página não encontrada." keywords={[`404`, `error`, `not found page`]} />
    <Header siteTitle="QueroPedir">
      <h1>404</h1>
    </Header>
    <div className="container become-partner">
      <div className="row">
        <h4><strong>Página não encontrada,</strong> <Link to="/">clique aqui</Link> para ir na página inicial.</h4>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
